import { createRouter, createWebHistory } from '@ionic/vue-router';

const routes = [
  {
    path: '/',
    redirect: '/first-page'
  },
  {
    path: '/first-page',
    name: 'First Page',
    component: () => import('@/views/pages/auth/FirstPage.vue')
  },
  {
    path: '/profiles',
    name: 'Manage Profiles',
    component: () => import('@/views/pages/ManageProfiles.vue')
  },{
    path: '/income-history',
    name: 'Income History',
    component: () => import('@/views/pages/IncomeHistory.vue')
  },
  {
    path: '/delete-users',
    name: 'Delete Users',
    component: () => import('@/views/pages/DeleteUsers.vue')
  },
  {
    path: '/Settlement',
    name: 'SettlementPage.vue',
    component: () => import('@/views/pages/SettlementPage.vue')
  },
  {
    path: '/agent',
    name: 'AgentPage.vue',
    component: () => import('@/views/pages/AgentPage.vue')
  },{
    path: '/verifyShop',
    name: 'VerifyPage.vue',
    component: () => import('@/views/pages/VerifyPage.vue')
  },{
    path: '/AgentOrganization',
    name: 'AgentOrganization.vue',
    component: () => import('@/views/pages/AgentOrganization.vue')
  },{
    path: '/agent-profiles',
    name: 'AgentProfile.vue',
    component: () => import('@/views/pages/profile/AgentProfile.vue')
  },{
    path: '/bank-profile',
    name: 'BankProfile.vue',
    component: () => import('@/views/pages/profile/BankProfile.vue')
  },
  {
    path: '/business-profiles',
    name: 'Business Profiles',
    component: () => import('@/views/pages/profile/BusinessProfile.vue')
  },
  {
    path: '/user-profiles',
    name: 'User Profiles',
    component: () => import('@/views/pages/profile/UserProfile.vue')
  },
  {
    path: '/theme-page',
    name: 'ThemePage',
    component: () => import('@/views/pages/ThemePage.vue')
  },
  {
    path: '/reset-password',
    name: 'Reset Password',
    component: () => import('@/views/pages/auth/ResetPassword.vue')
  },
  {
    path: '/add-users',
    name: 'Add Users',
    component: () => import('@/views/pages/AddUsers.vue')
  },
  {
    path: '/billing',
    name: 'Billing',
    component: () => import('@/views/pages/BillingPage.vue')
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('@/views/pages/DashboardPage.vue')
  },
  {
    path: '/pos',
    name: 'POS',
    component: () => import('@/views/pages/POSPage.vue')
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import('@/views/pages/SettingsPage.vue')
  },
  
  {
    path: '/newOrganisation',
    name: 'newOrganisation',
    component: () => import('@/views/pages/NewOrganization.vue')
  },
  {
    path: '/history',
    name: 'History',
    component: () => import('@/views/pages/TrxHistory.vue')
  },
  {
    path: '/incomeHistory',
    name: 'incomeHistory',
    component: () => import('@/views/pages/ShopIncome.vue')
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('@/views/pages/auth/RegisterPage.vue')
  },
  {
    path: '/sign-in',
    name: 'SignIn',
    component: () => import('@/views/pages/auth/SignIn.vue')
  },
  /* Re-route all sign-up logic to register
  {
    path: '/sign-up',
    name: 'SignUp',
    component: () => import('@/views/pages/auth/SignUp.vue')
  },*/
  {
    path: '/otp-validate/:id',
    name: 'OTP Validate',
    
    component: () => import('@/views/pages/auth/ValidateOTP.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
